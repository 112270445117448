import React from "react"
import { FormControl, FormHelperText, FormLabel, Input } from "@mui/joy"
import { NumericFormatProps, NumericFormat } from "react-number-format"
import { CustomField } from "src/models/infinicept/customField"
import { FieldComponentProps } from "./types"

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
  field: any
}

const NumericFormatAdapter = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatAdapter(props, ref) {
    const { onChange, field, ...other } = props
    const { validation1: validation } = field

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          })
        }}
        valueIsNumericString
        decimalScale={
          ["RegexMatch", "Integer"].includes(validation?.validationTypeName)
            ? 0
            : 2
        }
      />
    )
  },
)

function NumberField({ field, value, errors, onChange }: FieldComponentProps) {
  return (
    <FormControl required={field.isRequired} error={!!errors}>
      <FormLabel>{field.prompt}</FormLabel>
      <Input
        placeholder={field.placeholderText}
        type="text"
        onChange={(e) => onChange(field, { [e.target.name]: e.target.value })}
        name="#"
        value={value["#"]}
        slotProps={{
          input: {
            component: NumericFormatAdapter,
            field,
          },
        }}
      />
      {!!errors && <FormHelperText>{errors["#"]}</FormHelperText>}
    </FormControl>
  )
}

export default NumberField
