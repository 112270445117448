import { Button, Snackbar as JoySnackbar } from "@mui/joy"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { closeSnackbar, selectState } from "./snackbarSlice"
import { useAppDispatch, useAppSelector } from "src/app/hooks"

function Snackbar() {
  const dispatch = useAppDispatch()
  const { open, snackbarProps } = useAppSelector(selectState)

  return !!snackbarProps ? (
    <JoySnackbar
      variant="soft"
      color={snackbarProps.color}
      open={open}
      onClose={() => dispatch(closeSnackbar())}
      autoHideDuration={snackbarProps?.autoHideDuration ?? 5000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      startDecorator={<CheckCircleIcon />}
      key={snackbarProps.message}
      endDecorator={
        <Button
          onClick={() => dispatch(closeSnackbar())}
          size="sm"
          variant="soft"
          color="success"
        >
          Dismiss
        </Button>
      }
    >
      {snackbarProps.message}
    </JoySnackbar>
  ) : null
}

export default Snackbar
