import Button from "@mui/joy/Button"
import { FormControl, FormHelperText, FormLabel } from "@mui/joy"
import { FieldComponentProps } from "./types"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { FormEvent, useState } from "react"
import VisuallyHiddenInput from "src/components/VisuallyHiddenInput"

function FileUploadField({
  field,
  value,
  errors,
  onChange,
}: FieldComponentProps) {
  const [loading, setLoading] = useState(false)

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files?.[0]

    if (file) {
      setLoading(true)
      const reader = new FileReader()

      reader.onloadend = () => {
        const base64String = reader.result
        onChange(field, { "#": base64String })
        setLoading(false)
      }

      reader.readAsDataURL(file)
    }
  }

  const hasFile = !!value["#"]?.length

  return (
    <FormControl
      required={field.isRequired}
      error={!!errors}
      data-input-id={field.id}
    >
      <FormLabel>{field.prompt}</FormLabel>
      <Button
        component="label"
        role={undefined}
        tabIndex={-1}
        variant="outlined"
        color={!!errors ? "danger" : hasFile ? "success" : "neutral"}
        startDecorator={hasFile ? <CheckCircleIcon /> : <CloudUploadIcon />}
        loading={loading}
      >
        {hasFile ? "File Uploaded" : "Upload a file"}
        <VisuallyHiddenInput onChange={handleChange} type="file" />
      </Button>
      {!!errors && <FormHelperText>{errors["#"]}</FormHelperText>}
    </FormControl>
  )
}

export default FileUploadField
