import { createBrowserRouter } from "react-router-dom"
import Dashboard from "./layouts/dashboard/Dashboard"
import Settings from "./features/settings/Settings"
import ApplicationsTable from "./features/applicationsTable/ApplicationsTable"
import ApplicationEditor from "./features/applicationEditor/ApplicationEditor"
import { Navigate } from "react-router-dom"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
    children: [
      {
        path: "",
        element: <Navigate to="merchants" />,
      },
      {
        path: "merchants",
        element: <ApplicationsTable />,
      },
      { path: "merchants/create", element: <ApplicationEditor /> },
      {
        path: "merchants/app/:groupUid/:merchantApplicationUid",
        element: <ApplicationEditor />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
    ],
  },
])

export default router
