import { CancelToken } from "axios"
import axios from "./axiosConfig"
import { MerchantApplicationPostData } from "src/models/merchantApplicationPostData"

export const createMerchantApplication = (
  groupUid: string,
  postData: MerchantApplicationPostData,
  cancelToken?: CancelToken,
) => {
  return axios.post(`groups/${groupUid}/merchantApplications`, postData, {
    cancelToken,
  })
}

export const updateMerchantApplication = (
  groupUid: string,
  applicationUid: string,
  postData: MerchantApplicationPostData,
  cancelToken?: CancelToken,
) => {
  return axios.patch(
    `groups/${groupUid}/merchantApplications/${applicationUid}`,
    postData,
    {
      cancelToken,
    },
  )
}

export const getMerchantApplication = (
  groupUid: string,
  merchantApplicationUid: string,
  cancelToken?: CancelToken,
) => {
  return axios.get(
    `groups/${groupUid}/merchantApplications/${merchantApplicationUid}?include=lastUpdatedByUser,createdByUser,group.partner`,
    { cancelToken },
  )
}

export const getMerchantApplications = (
  params: any,
  cancelToken?: CancelToken,
) => {
  return axios.get(`merchantApplications`, { params, cancelToken })
}
