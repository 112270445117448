import { Autocomplete, FormControl, FormHelperText, FormLabel } from "@mui/joy"
import { FieldComponentProps } from "./types"
import { useEffect, useState } from "react"
import { zipWith } from "lodash"

type Option = { value: string; label: string }

function SelectField({ field, value, errors, onChange }: FieldComponentProps) {
  const [options, setOptions] = useState<Option[]>([])

  useEffect(() => {
    setOptions(
      zipWith(field.valuesList, field.optionsList, (value, label) => ({
        value,
        label,
      })),
    )
  }, [field])

  return (
    <FormControl required={field.isRequired} error={!!errors}>
      <FormLabel>{field.prompt}</FormLabel>
      <Autocomplete
        options={options}
        placeholder={
          !!field.placeholderText?.length
            ? field.placeholderText
            : "Select an option"
        }
        onChange={(_, value) => onChange(field, { "#": value?.value })}
        name="#"
        value={
          value["#"]?.length && !!options.length
            ? options.find((option) => option.value === value["#"])
            : null
        }
      />
      {!!errors && <FormHelperText>{errors["#"]}</FormHelperText>}
    </FormControl>
  )
}

export default SelectField
