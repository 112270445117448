import { Checkbox, FormControl } from "@mui/joy"
import { FieldComponentProps } from "./types"

function CheckboxField({ field, value, onChange }: FieldComponentProps) {
  return (
    <FormControl required={field.isRequired}>
      <Checkbox
        label={field.prompt}
        onChange={
          (e) =>
            onChange(field, { [e.target.name]: e.target.checked.toString() }) // infinicept stores "true" and "false" strings
        }
        name="#"
        checked={value["#"] === "true"}
      />
    </FormControl>
  )
}

export default CheckboxField
