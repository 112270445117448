import * as React from "react"
import { useColorScheme } from "@mui/joy/styles"
import IconButton, { IconButtonProps } from "@mui/joy/IconButton"

import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded"
import LightModeIcon from "@mui/icons-material/LightMode"
import { Button, Typography } from "@mui/joy"

export default function ColorSchemeToggle({
  onClick,
  sx,
  ...props
}: IconButtonProps) {
  const { mode, setMode } = useColorScheme()
  // const [mounted, setMounted] = React.useState(false)
  // React.useEffect(() => {
  //   setMounted(true)
  // }, [])
  // if (!mounted) {
  //   return (
  //     <IconButton
  //       size="sm"
  //       variant="outlined"
  //       color="neutral"
  //       {...props}
  //       sx={sx}
  //       disabled
  //     />
  //   )
  // }
  return (
    <Button
      id="toggle-mode"
      size="sm"
      variant="outlined"
      color="neutral"
      {...props}
      onClick={(event) => {
        if (mode === "light") {
          setMode("dark")
        } else {
          setMode("light")
        }
        onClick?.(event)
      }}
      startDecorator={
        mode === "light" ? <LightModeIcon /> : <DarkModeRoundedIcon />
      }
    >
      {mode === "light" ? "Light Theme" : "Dark Theme"}
    </Button>
  )
}
