import { FormControl, FormHelperText, FormLabel, Input } from "@mui/joy"
import { FieldComponentProps } from "./types"
import { FormEvent } from "react"
import { CustomFieldValue } from "src/models/infinicept/customFieldValue"
import { isEmpty } from "lodash"

function DateField({ field, value, errors, onChange }: FieldComponentProps) {
  const formatDate = (dateObj: CustomFieldValue) => {
    if (isEmpty(dateObj) || Object.values(dateObj).some((v) => !v?.length))
      return ""

    const year = dateObj.year
    const month = dateObj.month!.padStart(2, "0")
    const day = dateObj.day!.padStart(2, "0")

    return `${year}-${month}-${day}`
  }

  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    const dateToks = event.currentTarget.value.split("-")

    const value = {
      month: dateToks[1],
      day: dateToks[2],
      year: dateToks[0],
    }

    onChange(field, value)
  }

  return (
    <FormControl
      required={field.isRequired}
      error={!!errors}
      data-input-id={field.id}
    >
      <FormLabel>{field.prompt}</FormLabel>
      <Input
        placeholder={field.placeholderText}
        type="date"
        onChange={handleChange}
        name="#"
        value={formatDate(value)}
      />
      {!!errors && <FormHelperText>{errors["#"]}</FormHelperText>}
    </FormControl>
  )
}

export default DateField
