import { Check } from "@mui/icons-material"
import { Box, Stepper as JoyStepper, Step, StepIndicator } from "@mui/joy"
import { selectActiveStep } from "./applicationEditorSlice"
import { useAppSelector } from "src/app/hooks"

const labels = ["General", "Business", "Owner", "Banking", "Volume", "Fees"]

function Stepper() {
  const activeStep = useAppSelector(selectActiveStep)

  return (
    <JoyStepper sx={{ mb: 3 }}>
      {labels.map((label, index) => (
        <Step
          key={index}
          indicator={
            <StepIndicator
              variant={activeStep <= index ? "soft" : "solid"}
              color={activeStep < index ? "neutral" : "primary"}
            >
              {activeStep <= index ? index + 1 : <Check />}
            </StepIndicator>
          }
          sx={{
            "&::after": {
              ...(activeStep > index && { bgcolor: "primary.solidBg" }),
            },
          }}
        >
          <Box sx={{ display: { xs: "none", md: "block" } }}>{label}</Box>
        </Step>
      ))}
    </JoyStepper>
  )
}

export default Stepper
