import {
  Autocomplete,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Typography,
} from "@mui/joy"
import { FormEvent, Fragment, useEffect, useState } from "react"
import { FieldComponentProps } from "./types"
import { zipWith } from "lodash"

type Option = { value: string; label: string }

function ComplexAddressField({
  field,
  value,
  errors,
  onChange,
}: FieldComponentProps) {
  const [stateOptions, setStateOptions] = useState<Option[]>([])

  useEffect(() => {
    setStateOptions(
      zipWith(field.valuesList, field.optionsList, (value, label) => ({
        value,
        label,
      })),
    )
  }, [field])

  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    onChange(field, { [event.currentTarget.name]: event.currentTarget.value })
  }

  return (
    <Fragment key={field.id}>
      <Typography level="title-md">{field.prompt}</Typography>
      {!!errors && !!errors["#"] && (
        <FormHelperText
          sx={(theme) => ({ color: theme.palette.danger.solidBg })}
        >
          {errors["#"]}
        </FormHelperText>
      )}
      <FormControl required={field.isRequired} error={!!errors?.street1}>
        <FormLabel>Address Line 1</FormLabel>
        <Input
          type="text"
          onChange={handleChange}
          name="street1"
          value={value.street1}
        />
        {!!errors?.street1 && <FormHelperText>{errors.street1}</FormHelperText>}
      </FormControl>
      <FormControl error={!!errors?.street2}>
        <FormLabel>Address Line 2</FormLabel>
        <Input
          type="text"
          onChange={handleChange}
          name="street2"
          value={value.street2}
          error={!!errors?.street2}
        />
        {!!errors?.street2 && <FormHelperText>{errors.street2}</FormHelperText>}
      </FormControl>
      <FormControl required={field.isRequired} error={!!errors?.city}>
        <FormLabel>City</FormLabel>
        <Input
          type="text"
          onChange={handleChange}
          name="city"
          value={value.city}
          required={field.isRequired}
          error={!!errors?.city}
        />
        {!!errors?.city && <FormHelperText>{errors.city}</FormHelperText>}
      </FormControl>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        <FormControl
          required={field.isRequired}
          sx={{ flex: 1 }}
          error={!!errors?.state}
        >
          <FormLabel>State</FormLabel>
          <Autocomplete
            options={stateOptions}
            placeholder="Select an option"
            onChange={(_, value) => onChange(field, { state: value?.value })}
            name="state"
            value={
              value.state?.length && !!stateOptions.length
                ? stateOptions.find((option) => option.value === value.state)
                : null
            }
          />
          {!!errors?.state && <FormHelperText>{errors.state}</FormHelperText>}
        </FormControl>
        <FormControl
          required={field.isRequired}
          error={!!errors?.zip}
          sx={{ minWidth: 0 }}
        >
          <FormLabel>Zip</FormLabel>
          <Input
            type="text"
            onChange={handleChange}
            name="zip"
            value={value.zip}
          />
          {!!errors?.zip && <FormHelperText>{errors.zip}</FormHelperText>}
        </FormControl>
      </Stack>
    </Fragment>
  )
}

export default ComplexAddressField
