import CheckboxField from "./CheckboxField"
import ComplexNameField from "./ComplexNameField"
import ComplexAddressField from "./ComplexAddressField"
import NumberField from "./NumberField"
import SelectField from "./SelectField"
import TextField from "./TextField"
import DateField from "./DateField"
import * as validators from "./validators"
import { CustomField } from "src/models/infinicept/customField"
import { CustomFieldValue } from "src/models/infinicept/customFieldValue"
import FileUploadField from "./FileUploadField"

export enum FieldType {
  Text = "text",
  Tel = "tel",
  Email = "email",
  Password = "password",
  Url = "url",
  Number = "number",
  Select = "select",
  Special_CheckboxShowHide = "Special_CheckboxShowHide",
  Complex_Address = "Complex_Address",
  Complex_Date_MonthDayYear = "Complex_Date_MonthDayYear",
  Complex_FullName = "Complex_FullName",
  FileUpload = "FileUpload",
}

export interface FieldTypeComponent {
  validator?: (
    field: CustomField,
    value: CustomFieldValue,
  ) => { [valueName: string]: string } | undefined
  component: React.ComponentType<any>
}

export interface FieldTypeComponentMap {
  [FieldType.Text]: FieldTypeComponent
  [FieldType.Tel]: FieldTypeComponent
  [FieldType.Email]: FieldTypeComponent
  [FieldType.Password]: FieldTypeComponent
  [FieldType.Url]: FieldTypeComponent
  [FieldType.Number]: FieldTypeComponent
  [FieldType.Select]: FieldTypeComponent
  [FieldType.Special_CheckboxShowHide]: FieldTypeComponent
  [FieldType.Complex_Address]: FieldTypeComponent
  [FieldType.Complex_Date_MonthDayYear]: FieldTypeComponent
  [FieldType.Complex_FullName]: FieldTypeComponent
  [FieldType.FileUpload]: FieldTypeComponent
}

export const fieldTypes: FieldTypeComponentMap = {
  [FieldType.Text]: {
    validator: validators.textField,
    component: TextField,
  },
  [FieldType.Tel]: {
    validator: validators.phoneField,
    component: TextField,
  },
  [FieldType.Email]: {
    validator: validators.emailField,
    component: TextField,
  },
  [FieldType.Password]: {
    validator: validators.textField,
    component: TextField,
  },
  [FieldType.Url]: {
    validator: validators.urlField,
    component: TextField,
  },
  [FieldType.Number]: {
    validator: validators.numberField,
    component: NumberField,
  },
  [FieldType.Select]: {
    validator: validators.textField,
    component: SelectField,
  },
  [FieldType.Special_CheckboxShowHide]: {
    component: CheckboxField,
  },
  [FieldType.Complex_Address]: {
    validator: validators.addressField,
    component: ComplexAddressField,
  },
  [FieldType.Complex_Date_MonthDayYear]: {
    validator: validators.dateField,
    component: DateField,
  },
  [FieldType.Complex_FullName]: {
    validator: validators.nameField,
    component: ComplexNameField,
  },
  [FieldType.FileUpload]: {
    validator: validators.nameField,
    component: FileUploadField,
  },
}
