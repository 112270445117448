import { useAppDispatch } from "src/app/hooks"
import { SnackbarProps } from "./snackbarSlice"
import { openSnackbar } from "./snackbarSlice"

const useSnackbar = () => {
  const dispatch = useAppDispatch()

  return (snackbarProps: SnackbarProps) => dispatch(openSnackbar(snackbarProps))
}

export default useSnackbar
