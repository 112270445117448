import { useAuth } from "react-oidc-context"
import LoadingBlanket from "./components/LoadingBlanket"
import router from "./router"
import { RouterProvider } from "react-router-dom"
import Login from "./features/login/Login"

function App() {
  const auth = useAuth()

  switch (auth.activeNavigator) {
    case "signinSilent":
      return <div>Signing you in...</div>
    case "signoutRedirect":
      return <div>Signing you out...</div>
  }

  if (auth.isLoading) {
    return <LoadingBlanket />
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>
  }

  if (auth.isAuthenticated) {
    return <RouterProvider router={router} />
  }

  return <Login />
}

export default App
