import React, { useEffect, useState } from "react"
// import DataTable from "src/components/dataTable/DataTableOld"
import DataTable, { AsyncHandlerProps, Row } from "src/components/DataTable"
import { getMerchantApplications } from "src/api/merchantApplications"
import { MerchantApplication } from "src/models/merchantApplication"
import { PaginatedResult } from "src/models/paginatedResult"
import * as dayjs from "dayjs"
import { MoreVert } from "@mui/icons-material"
import {
  Dropdown,
  MenuButton,
  IconButton,
  Menu,
  MenuItem,
  Chip,
} from "@mui/joy"
import { Link as RouterLink } from "react-router-dom"

const columns = [
  { key: "group.partner.name", label: "Partner", sortable: true },
  { key: "group.name", label: "Group", sortable: true },
  { key: "merchantName", label: "Merchant", sortable: true },
  { key: "createdByUser.userName", label: "Created By", sortable: true },
  {
    key: "createdAt",
    label: "Created At",
    sortable: true,
    cellRenderer: (value: any) => dayjs.utc(value).local().format("llll"),
  },
  {
    key: "infiniceptApplication.status",
    label: "Infinicept",
    sortable: true,
    cellRenderer: (
      value: any, //todo
    ) => (
      <Chip
        color={(() => {
          switch (value) {
            case "Submitted":
              return "primary"
            case "Incomplete":
              return "warning"
            default:
              return "neutral"
          }
        })()}
        variant={!value ? "plain" : "soft"}
      >
        {value ?? "Not Started"}
      </Chip>
    ),
  },
  {
    key: "boardJackHenry",
    label: "Jack Henry",
    cellRenderer: (value: keyof Row) =>
      value ? <Chip variant="soft">Pending</Chip> : null,
    sortable: true,
  },
  {
    key: "boardWorldpay",
    label: "Worldpay",
    cellRenderer: (value: keyof Row) =>
      value ? <Chip variant="soft">Pending</Chip> : null,
    sortable: true,
  },
  {
    key: "actions",
    cellStyles: { width: 50, textAlign: "right" },
    cellRenderer: (_: keyof Row, row: Row) => (
      <Dropdown>
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{ root: { color: "neutral" } }}
        >
          <MoreVert />
        </MenuButton>
        <Menu>
          <MenuItem
            component={RouterLink}
            to={`/merchants/app/${row.group.uniqueId}/${row.uniqueId}`}
          >
            Edit
          </MenuItem>
        </Menu>
      </Dropdown>
    ),
  },
]

function ApplicationsTable() {
  const [data, setData] = useState<PaginatedResult<MerchantApplication> | null>(
    null,
  )

  const fetchData = ({
    order,
    orderBy,
    page,
    rowsPerPage,
  }: AsyncHandlerProps) => {
    return getMerchantApplications({
      include: "group.partner,infiniceptApplication,createdByUser",
      orderBy: `${orderBy}.${order}`,
      pageSize: rowsPerPage,
      page: page + 1,
    })
      .then((res) => res.data)
      .then((applications: PaginatedResult<MerchantApplication>) => {
        setData(applications)
      })
  }

  return (
    <div>
      <DataTable
        title="Merchants"
        columns={columns}
        rows={data?.data ?? []}
        rowKey="uniqueId"
        defaultOrderBy="createdAt"
        defaultSortOrder="desc"
        totalRows={data?.totalItems}
        asyncHandler={fetchData}
        async
      />
    </div>
  )
}

export default ApplicationsTable
