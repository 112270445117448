import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import snackbarReducer from "src/features/snackbar/snackbarSlice"
import applicationEditorReducer from "src/features/applicationEditor/applicationEditorSlice"

export const store = configureStore({
  reducer: {
    snackbar: snackbarReducer,
    applicationEditor: applicationEditorReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
