import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "src/app/store"
import { getMerchantApplication } from "src/api/merchantApplications"

type Status = "pristine" | "idle" | "loading" | "failed"

export interface ApplicationEditorState {
  status: Status
  activeStep: number
  application: any | null
}

const initialState: ApplicationEditorState = {
  status: "pristine",
  activeStep: 0,
  application: null,
}

export const getMerchantApplicationAsync = createAsyncThunk(
  "applicationEditor/getMerchantApplication",
  async ({
    groupUid,
    merchantApplicationUid,
  }: {
    groupUid: string
    merchantApplicationUid: string
  }) => {
    const response = await getMerchantApplication(
      groupUid,
      merchantApplicationUid,
    )
    // The value we return becomes the `fulfilled` action payload
    return response.data
  },
)

export const applicationEditorSlice = createSlice({
  name: "merchantApplication",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    reset: (state) => (state = initialState),
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload
    },
    setStatus: (state, action: PayloadAction<Status>) => {
      state.status = action.payload
    },
    incrementStep: (state) => {
      state.activeStep += 1
    },
    decrementStep: (state) => {
      state.activeStep -= 1
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMerchantApplicationAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getMerchantApplicationAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.application = action.payload
        state.activeStep = Math.min(action.payload.lastCompletedStep + 1, 5)
      })
      .addCase(getMerchantApplicationAsync.rejected, (state) => {
        state.status = "failed"
      })
  },
})

export const { reset, setActiveStep, incrementStep, decrementStep, setStatus } =
  applicationEditorSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectStatus = (state: RootState) => state.applicationEditor.status
export const selectActiveStep = (state: RootState) =>
  state.applicationEditor.activeStep
export const selectApplication = (state: RootState) =>
  state.applicationEditor.application

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState())
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount))
//     }
//   }

export default applicationEditorSlice.reducer
