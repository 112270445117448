import { Button } from "@mui/joy"
import { Box, useColorScheme } from "@mui/joy"
import { useAuth } from "react-oidc-context"
import evLogoLight from "src/assets/img/ev-logo-hor-light.png"
import evLogoDark from "src/assets/img/ev-logo-hor-dark.png"

function Login() {
  const auth = useAuth()
  const { mode } = useColorScheme()

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="100vh"
    >
      <Box>
        <img
          src={mode === "light" ? evLogoDark : evLogoLight}
          alt="Everview Logo"
          style={{ width: 300 }}
        />
        <div
          style={{
            fontFamily: "EverViewGothic",
            fontSize: 26,
            color: mode === "light" ? "rgb(0,50,107)" : "#FFF",
          }}
        >
          Merchant Boarding
        </div>
      </Box>
      <Button
        size="lg"
        onClick={() => void auth.signinRedirect()}
        sx={{ mt: 3, width: 300 }}
      >
        Sign in
      </Button>
    </Box>
  )
}

export default Login
