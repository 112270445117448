import { Box, Typography, Link, Breadcrumbs as JoyBreadcrumbs } from "@mui/joy"
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded"
import HomeRoundedIcon from "@mui/icons-material/HomeRounded"
import { useLocation, Link as RouterLink } from "react-router-dom"

type BreadCrumbItem = {
  label: string
  href?: string
}

type BreadcrumbData = {
  [pathPart: string]: BreadCrumbItem
}

const crumbs: BreadcrumbData = {
  merchants: { label: "Merchants", href: "/merchants" },
  create: { label: "Create New Merchant" },
  app: { label: "Application" },
  settings: { label: "Settings" },
}

function Breadcrumbs() {
  const location = useLocation()

  const renderBreadcrumbs = () => {
    const pathParts = location.pathname
      .split("/")
      .filter((part) => !!part.length)
    const lastIndex = pathParts.length - 1

    return pathParts.map((pathPart, index) => {
      const crumb = crumbs[pathPart]
      const key = `${pathPart}-${index}`

      if (!crumb) {
        return null
      }

      if (index === lastIndex || !crumb.href) {
        return (
          <Typography key={key} color="primary" fontWeight={500} fontSize={12}>
            {crumb.label}
          </Typography>
        )
      }

      return (
        <Link
          component={RouterLink}
          key={key}
          underline="hover"
          color="neutral"
          to={crumb.href ?? "#"}
          fontSize={12}
          fontWeight={500}
        >
          {crumb.label}
        </Link>
      )
    })
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <JoyBreadcrumbs
        size="sm"
        aria-label="breadcrumbs"
        separator={<ChevronRightRoundedIcon fontSize="small" />}
        sx={{ pl: 0 }}
      >
        <Link
          underline="none"
          color="neutral"
          component={RouterLink}
          to="/"
          aria-label="Home"
        >
          <HomeRoundedIcon fontSize="small" />
        </Link>
        {renderBreadcrumbs()}
      </JoyBreadcrumbs>
    </Box>
  )
}

export default Breadcrumbs
