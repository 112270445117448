import { Box } from "@mui/joy"
import useScript from "./useScript"
import React from "react"
import Header from "./Header"
import Sidebar from "./Sidebar"
import { Outlet } from "react-router-dom"
import Breadcrumbs from "./Breadcrumbs"
import Snackbar from "src/features/snackbar/Snackbar"

type Props = {}

const useEnhancedEffect =
  typeof window !== "undefined" ? React.useLayoutEffect : React.useEffect

function Dashboard({}: Props) {
  const status = useScript(`https://unpkg.com/feather-icons`)

  useEnhancedEffect(() => {
    // Feather icon setup: https://github.com/feathericons/feather#4-replace
    // @ts-ignore
    if (typeof feather !== "undefined") {
      // @ts-ignore
      feather.replace()
    }
  }, [status])

  return (
    <Box sx={{ display: "flex", minHeight: "100dvh" }}>
      <Header />
      <Sidebar />
      <Box
        component="main"
        className="MainContent"
        sx={{
          px: {
            xs: 2,
            md: 6,
          },
          pt: {
            xs: "calc(12px + var(--Header-height))",
            sm: "calc(12px + var(--Header-height))",
            md: 3,
          },
          pb: {
            xs: 2,
            sm: 2,
            md: 3,
          },
          flex: 1,
          display: "flex",
          flexDirection: "column",
          minWidth: 0,
          height: "100dvh",
          // maxHeight: "100dvh",
          gap: 1,
          overflow: "auto",
        }}
      >
        <Breadcrumbs />
        <Outlet />
        <Snackbar />
      </Box>
    </Box>
  )
}

export default Dashboard
