import { useEffect, useState } from "react"
import { CustomFieldAnswer } from "src/models/infinicept/customFieldAnswer"
import { cloneDeep, groupBy, isEmpty, map, setWith, sortBy } from "lodash"
import { Box, Button, Stack } from "@mui/joy"
import FormStep from "./FormStep"
import {
  getApplicationDetails,
  getTemplate,
  putApplication,
} from "src/api/infinicept"
import { Template } from "src/models/infinicept/template"
import { CustomField } from "src/models/infinicept/customField"
import { FieldType } from "./fieldTypes"
import { CustomFieldValue } from "src/models/infinicept/customFieldValue"
import React from "react"
import Spinner from "./Spinner"
import { useNavigate, useParams } from "react-router-dom"
import { PutApplicationResponse } from "src/models/infinicept/putApplicationResponse"
import { ApplicationDetails } from "src/models/infinicept/applicationDetails"
import axios from "axios"
import testData from "./testData"
import useSnackbar from "src/features/snackbar/useSnackbar"
import {
  decrementStep,
  incrementStep,
  selectActiveStep,
} from "../applicationEditorSlice"
import { useDispatch } from "react-redux"
import { useAppSelector } from "src/app/hooks"

interface HiddenFields {
  [stepNumber: number]: { [fieldId: number]: boolean }
}

type Steps = CustomField[][]

export type GroupedAnswers = {
  [fieldId: number]: CustomFieldAnswer
}

const stackStyles = {
  maxWidth: "800px",
}

function InfiniceptForm() {
  const [isFetching, setFetching] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [hidden, setHidden] = useState<HiddenFields>()
  const [answers, setAnswers] = useState<GroupedAnswers>()
  const [steps, setSteps] = useState<Steps>([])

  const params = useParams()
  const openSnackbar = useSnackbar()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const activeStep = useAppSelector(selectActiveStep)
  // subract one from activeStep since step 0 is not included in infinicept
  const activeIndex = activeStep - 1

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source()

    const fetchData = () => {
      setFetching(true)

      // fetch the existing application from infinicept (if it exists)
      const appDetailsPromise = getApplicationDetails(
        params.groupUid!,
        params.merchantApplicationUid!,
        cancelTokenSource.token,
      )
        .then((res) => res.data)
        .catch((err) => {
          if (err?.response?.status === 404) {
            // if the application does not exist that means we are starting a new one
            // so let's ignore 404 error here
            return Promise.resolve()
          }
          throw err
        })

      // fetch the application template that includes all of the CustomField definitions
      const templatePromise = getTemplate().then((res) => res.data)

      // Wait for all the promises to come back
      Promise.all([appDetailsPromise, templatePromise]).then(
        ([appDetails, template]: [
          appDetails: ApplicationDetails | null,
          template: Template,
        ]) => {
          // if we have answers from the ApplicationDetails, use those. if not,
          // prepoulate the answers from the template
          const answers =
            appDetails?.customFields ??
            template.merchantApplicationTemplate.customFieldAnswers

          const { customFields: fields } = template
          const answersByFieldId = answers.reduce(
            (acc: GroupedAnswers, cur) => {
              acc[cur.id] = cur
              return acc
            },
            {},
          )

          setAnswers(answersByFieldId)

          const hidden: HiddenFields = {}

          // set the hidden state of each field based on RegexPattern
          fields
            .filter(
              (field) =>
                field.fieldType === FieldType.Special_CheckboxShowHide &&
                field.showHidePattern?.length &&
                answersByFieldId[field.id].value["#"] !== "true",
            )
            .forEach((checkboxField) => {
              var regExp = new RegExp(checkboxField.showHidePattern!)
              fields.forEach((field) => {
                if (
                  checkboxField.stepNumber === field.stepNumber &&
                  !!field.userDefinedId.match(regExp)
                ) {
                  setWith(
                    hidden,
                    `${field.stepNumber}.${field.id}`,
                    true,
                    Object,
                  )
                }
              })
            })

          setHidden(hidden)

          const fieldMatrix = map(groupBy(fields, "stepNumber"), (group) =>
            sortBy(group, "orderingIndex"),
          )

          setSteps(fieldMatrix)
          setFetching(false)
        },
      )
    }

    fetchData()

    return () => cancelTokenSource.cancel("Component has been unmounted")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (field: CustomField, valueObj: CustomFieldValue) => {
    setAnswers({
      ...answers,
      [field.id]: {
        ...answers![field.id],
        value: { ...answers![field.id].value, ...valueObj },
      },
    })

    if (field.fieldType === FieldType.Special_CheckboxShowHide) {
      const newHidden = cloneDeep(hidden)!

      const regExp = new RegExp(field.showHidePattern!)
      const isHidden = valueObj["#"] !== "true" // infinicept stores bools as strings like this

      steps[activeIndex].forEach((field) => {
        if (!!field.userDefinedId.match(regExp)) {
          setWith(
            newHidden,
            `${field.stepNumber}.${field.id}`,
            isHidden,
            Object,
          )
        }
      })

      setHidden(newHidden)
    }
  }

  const isLastStep = () => activeStep === steps.length

  const next = () => {
    const stepAnswers = steps[activeIndex]
      .filter((field) => !hidden![field.id])
      .map((field) => answers![field.id])
      .filter((answer) =>
        Object.values(answer.value).some((val) => val !== null && val !== ""),
      )

    setSubmitting(true)

    return putApplication(
      params.groupUid!,
      params.merchantApplicationUid!,
      stepAnswers,
      activeStep,
    )
      .then((res) => res.data)
      .then((data: PutApplicationResponse) => {
        setSubmitting(false)
        const fieldIds = steps[activeIndex].map((field) => field.id)

        // infinicept validates the entire application even though we're only
        // doing a partial submit. When navigating to the next step, we only
        // care about validation errors from the current step
        const errors =
          data.errors?.filter((error) => fieldIds.includes(error.field)) ?? []

        if (!isEmpty(errors)) {
          return Promise.reject(errors)
        }
        if (isLastStep()) {
          openSnackbar({
            color: "success",
            message: "Your application has been submitted successfully",
          })
          navigate("/merchants")
        } else {
          dispatch(incrementStep())
        }
      })
  }

  const back = () => dispatch(decrementStep())

  const prefill = () => {
    setAnswers(cloneDeep(testData))
  }

  return !isFetching && !isEmpty(steps) && !!hidden && !!answers ? (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          width: "100%",
          justifyContent: "center",
        }}
      >
        <FormStep
          fields={steps[activeIndex].filter(
            (field) => !hidden[field.stepNumber]?.[field.id],
          )}
          answers={answers}
          onChange={handleChange}
          onSubmit={next}
          disabled={isSubmitting}
        />
      </Box>
      <Stack sx={stackStyles} direction="row" justifyContent="space-between">
        <Button disabled={isSubmitting} onClick={back} variant="plain">
          Back
        </Button>
        <Button onClick={prefill} variant="outlined">
          Prefill
        </Button>
        <Button
          type="submit"
          form="formStep"
          sx={{ minWidth: 200 }}
          loading={isSubmitting}
        >
          {isLastStep() ? "Submit" : "Next"}
        </Button>
      </Stack>
    </React.Fragment>
  ) : (
    <Box width="100%" justifyContent="center" display="flex">
      <Spinner />
    </Box>
  )
}

export default InfiniceptForm
