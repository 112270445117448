import axios from "axios"
import { User } from "oidc-client-ts"
import oidcConfig from "src/auth/config"

const axiosInstance = axios.create({
  baseURL: "/api",
  headers: {
    "Content-Type": "application/json",
  },
})

axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = getAccessToken()

    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

const getUser = () => {
  const oidcStorage = localStorage.getItem(
    `oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`,
  )
  if (!oidcStorage) {
    return null
  }

  return User.fromStorageString(oidcStorage)
}

const getAccessToken = () => {
  const user = getUser()
  return user?.access_token
}

export default axiosInstance
