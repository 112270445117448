import { FormControl, FormHelperText, FormLabel, Input } from "@mui/joy"
import { FieldComponentProps } from "./types"

function TextField({ field, value, errors, onChange }: FieldComponentProps) {
  return (
    <FormControl
      required={field.isRequired}
      error={!!errors}
      data-input-id={field.id}
    >
      <FormLabel>{field.prompt}</FormLabel>
      <Input
        placeholder={field.placeholderText}
        type={field.fieldType}
        onChange={(e) => onChange(field, { [e.target.name]: e.target.value })}
        name="#"
        value={value["#"] ?? ""}
      />
      {!!errors && <FormHelperText>{errors["#"]}</FormHelperText>}
    </FormControl>
  )
}

export default TextField
