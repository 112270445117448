import { User, WebStorageStateStore } from "oidc-client-ts"
import { AuthProviderProps } from "react-oidc-context"

const oidcConfig: AuthProviderProps = {
  authority:
    import.meta.env.VITE_OIDC_AUTHORITY ?? window.location.origin + "/api",
  client_id: "react-client",
  redirect_uri:
    import.meta.env.VITE_OIDC_REDIRECT_URI ?? window.location.origin,
  scope: "openid profile email roles offline_access",
  response_type: "code",
  post_logout_redirect_uri:
    import.meta.env.VITE_OIDC_POST_LOGOUT_REDIRECT_URI ??
    window.location.origin,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  automaticSilentRenew: true,
  onSigninCallback: (_user: User | void): void => {
    // this needs to be here to remove the payload from the URL upon successful login.
    window.history.replaceState({}, document.title, window.location.pathname)
  },
}

export default oidcConfig
