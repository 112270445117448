import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { store } from "./app/store"
import { AuthProvider } from "react-oidc-context"
import oidcConfig from "./auth/config"
import { CssBaseline, CssVarsProvider } from "@mui/joy"
import {
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  experimental_extendTheme as extendMaterialTheme,
  THEME_ID,
} from "@mui/material/styles"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import App from "./App"
import utc from "dayjs/plugin/utc"
import * as dayjs from "dayjs"

import "@fontsource/inter/300.css"
import "@fontsource/inter/400.css"
import "@fontsource/inter/500.css"
import "@fontsource/inter/700.css"
import "src/assets/css/fonts.css"
import { LocalizationProvider } from "@mui/x-date-pickers"

dayjs.extend(utc)

const materialTheme = extendMaterialTheme()

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider {...oidcConfig}>
        <MaterialCssVarsProvider theme={{ [THEME_ID]: materialTheme }}>
          <CssVarsProvider>
            <CssBaseline />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <App />
            </LocalizationProvider>
          </CssVarsProvider>
        </MaterialCssVarsProvider>
      </AuthProvider>
    </Provider>
  </React.StrictMode>,
)
