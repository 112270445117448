import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { RootState } from "src/app/store"

export interface SnackbarProps {
  color: "danger" | "neutral" | "primary" | "success" | "warning"
  autoHideDuration?: number | null
  message: string
}

export interface SnackbarState {
  open: boolean
  snackbarProps: SnackbarProps | null
}

const initialState: SnackbarState = {
  open: false,
  snackbarProps: null,
}

// this is async because we cannot clear out snackbarProps until
// the snackbar's close animation finishes
export const closeSnackbar = createAsyncThunk(
  "snackbar/closeSnackbar",
  async () => {
    return await new Promise((resolve) => setTimeout(resolve, 300))
  },
)

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    openSnackbar: (state, action: PayloadAction<SnackbarProps>) => {
      state.open = true
      state.snackbarProps = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(closeSnackbar.pending, (state) => {
        state.open = false
      })
      .addCase(closeSnackbar.fulfilled, (state) => {
        state.snackbarProps = null
      })
  },
})

export const selectState = (state: RootState) => state.snackbar

export const { openSnackbar } = snackbarSlice.actions

export default snackbarSlice.reducer
