import { Box, Typography } from "@mui/joy"
import Stepper from "./Stepper"
import General from "./General"
import { useAppDispatch, useAppSelector } from "src/app/hooks"
import {
  getMerchantApplicationAsync,
  reset,
  selectStatus,
  selectActiveStep,
  setStatus,
} from "./applicationEditorSlice"
import InfiniceptForm from "./infiniceptApplication/InfiniceptForm"
import { useEffect } from "react"
import { useParams } from "react-router-dom"

function ApplicationEditor() {
  const activeStep = useAppSelector(selectActiveStep)
  const reducerStatus = useAppSelector(selectStatus)
  const { groupUid, merchantApplicationUid } = useParams()
  const dispatch = useAppDispatch()

  // useEffect(() => {
  //   console.log("stuff")
  //   return () => {
  //     dispatch(reset())
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  useEffect(() => {
    dispatch(reset())
    if (groupUid && merchantApplicationUid) {
      dispatch(
        getMerchantApplicationAsync({
          groupUid: groupUid!,
          merchantApplicationUid: merchantApplicationUid!,
        }),
      )
    } else {
      dispatch(setStatus("idle"))
    }
  }, [groupUid, merchantApplicationUid, dispatch])

  if (reducerStatus === "pristine") {
    return null
  }

  if (reducerStatus === "loading") {
    return "Loading..."
  }

  return (
    <Box sx={{ maxWidth: 800 }}>
      <Typography level="h2" sx={{ mb: 3 }}>
        New Application
      </Typography>
      <Stepper />

      {activeStep === 0 ? <General /> : <InfiniceptForm />}
    </Box>
  )
}

export default ApplicationEditor
