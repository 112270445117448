import React, { FormEvent, useState } from "react"
import { fieldTypes } from "./fieldTypes"
import { GroupedAnswers } from "./InfiniceptForm"
import { Stack } from "@mui/joy"
import { isEmpty, omit } from "lodash"
import { CustomField } from "src/models/infinicept/customField"
import { CustomFieldValue } from "src/models/infinicept/customFieldValue"
import { PutApplicationResponseError } from "src/models/infinicept/putApplicationResponse"

type Props = {
  fields: CustomField[]
  answers: GroupedAnswers
  onChange: any
  onSubmit: any
  disabled: boolean
}

const stackStyles = {
  border: "none",
  padding: 0,
  width: "100%",
  mb: 5,
}

type Errors = {
  [fieldId: number]: { [valueName: string]: string }
}

function FormStep({ fields, answers, onChange, onSubmit, disabled }: Props) {
  const [errors, setErrors] = useState<Errors>({})

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const errors = validate()
    setErrors(errors)

    if (!isEmpty(errors)) {
      document
        .querySelector(`[data-input-id='${Object.keys(errors)[0]}']`)
        ?.scrollIntoView({ behavior: "smooth" })
      return
    }

    onSubmit().catch((errors: PutApplicationResponseError[]) =>
      setErrors(
        errors.reduce(
          (acc, cur) => ({ ...acc, [cur.field]: { "#": cur.message } }),
          {},
        ),
      ),
    )
  }

  const validate = (): Errors => {
    let errors: Errors = {}

    fields.forEach((field) => {
      const validator = fieldTypes[field.fieldType].validator
      if (validator) {
        const errorMessage = validator!(field, answers[field.id].value)
        if (!!errorMessage) {
          errors[field.id] = errorMessage
        }
      }
    })

    return errors
  }

  const handleChange = (field: CustomField, valueObj: CustomFieldValue) => {
    onChange(field, valueObj)

    if (!!errors[field.id]) {
      const validator = fieldTypes[field.fieldType].validator
      const errorMessage = validator ? validator(field, valueObj) : null

      if (!errorMessage) {
        setErrors(omit(errors, [field.id]))
      }
    }
  }

  return (
    <form
      id="formStep"
      noValidate
      onSubmit={handleSubmit}
      style={{ width: "100%" }}
    >
      <Stack
        component="fieldset"
        sx={stackStyles}
        spacing={2}
        disabled={disabled}
      >
        {fields.map((field) =>
          React.createElement(fieldTypes[field.fieldType]?.component, {
            field,
            key: field.id,
            value: answers[field.id].value,
            onChange: handleChange,
            errors: errors[field.id],
          }),
        )}
      </Stack>
    </form>
  )
}

export default FormStep
