import * as React from "react"
import Box from "@mui/joy/Box"
import Table from "@mui/joy/Table"
import Typography from "@mui/joy/Typography"
import Sheet from "@mui/joy/Sheet"
import Checkbox from "@mui/joy/Checkbox"
import FormControl from "@mui/joy/FormControl"
import FormLabel from "@mui/joy/FormLabel"
import IconButton from "@mui/joy/IconButton"
import Link from "@mui/joy/Link"
import Tooltip from "@mui/joy/Tooltip"
import Select from "@mui/joy/Select"
import Option from "@mui/joy/Option"
import DeleteIcon from "@mui/icons-material/Delete"
import FilterListIcon from "@mui/icons-material/FilterList"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import { visuallyHidden } from "@mui/utils"
import { get } from "lodash"
import { LinearProgress } from "@mui/joy"
import Delayed from "./Delayed"

function labelDisplayedRows({
  from,
  to,
  count,
}: {
  from: number
  to: number
  count: number
}) {
  return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = "asc" | "desc"

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

interface EnhancedTableProps {
  columns: Column[]
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Row) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: Order
  orderBy: keyof Row
  rowCount: number
  selectable?: boolean
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    columns,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    selectable,
  } = props
  const createSortHandler =
    (property: keyof Row) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property)
    }

  return (
    <thead>
      <tr>
        {selectable && (
          <th>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              slotProps={{
                input: {
                  "aria-label": "select all",
                },
              }}
              sx={{ verticalAlign: "sub" }}
            />
          </th>
        )}
        {columns.map((column) => {
          const active = orderBy === column.key
          return (
            <th
              key={column.key}
              aria-sort={
                active
                  ? ({ asc: "ascending", desc: "descending" } as const)[order]
                  : undefined
              }
              style={column.cellStyles ?? {}}
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              {column.sortable ? (
                <Link
                  underline="none"
                  color="neutral"
                  textColor={active ? "primary.plainColor" : undefined}
                  component="button"
                  onClick={createSortHandler(column.key)}
                  fontWeight="lg"
                  startDecorator={
                    column.numeric ? (
                      <ArrowDownwardIcon sx={{ opacity: active ? 1 : 0 }} />
                    ) : null
                  }
                  endDecorator={
                    !column.numeric ? (
                      <ArrowDownwardIcon sx={{ opacity: active ? 1 : 0 }} />
                    ) : null
                  }
                  sx={{
                    "& svg": {
                      transition: "0.2s",
                      transform:
                        active && order === "desc"
                          ? "rotate(0deg)"
                          : "rotate(180deg)",
                    },
                    "&:hover": { "& svg": { opacity: 1 } },
                  }}
                >
                  {column.label}
                  {active ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </Link>
              ) : (
                column.label
              )}
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

interface EnhancedTableToolbarProps {
  title: string
  numSelected: number
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { title, numSelected } = props

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        py: 1,
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: "background.level1",
        }),
        borderTopLeftRadius: "var(--unstable_actionRadius)",
        borderTopRightRadius: "var(--unstable_actionRadius)",
      }}
    >
      {numSelected > 0 ? (
        <Typography sx={{ flex: "1 1 100%" }} component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          level="body-lg"
          sx={{ flex: "1 1 100%" }}
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton size="sm" color="danger" variant="solid">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton size="sm" variant="outlined" color="neutral">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  )
}

type Column = {
  key: string
  label?: string
  sortable?: boolean
  numeric?: boolean
  disablePadding?: boolean
  cellStyles?: object
  cellRenderer?: (value: any, row: Row) => any
}

export type Row = {
  [key: string | number]: any
}

export type AsyncHandlerProps = {
  order: "asc" | "desc"
  orderBy: keyof Row
  page: number
  rowsPerPage: number
}

type TableProps = {
  title: string
  columns: Column[]
  rows: Row[]
  rowKey: string | number
  selectable?: boolean
  defaultRowsPerPage?: number
  defaultOrderBy?: string
  defaultSortOrder?: Order
  async?: boolean
  asyncHandler?: (props: AsyncHandlerProps) => any
  totalRows?: number
}

export default function DataTable({
  title,
  columns,
  rows,
  rowKey,
  selectable = false,
  defaultRowsPerPage = 10,
  defaultOrderBy = undefined,
  defaultSortOrder = "asc",
  async = false,
  totalRows = undefined,
  asyncHandler = undefined,
}: TableProps) {
  const [order, setOrder] = React.useState<Order>(defaultSortOrder)
  const [orderBy, setOrderBy] = React.useState<keyof Row>(
    defaultOrderBy ?? columns[0]?.key,
  )
  const [selected, setSelected] = React.useState<readonly string[]>([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage)
  const [loading, setLoading] = React.useState(false)

  const rowCount = totalRows !== undefined ? totalRows : rows.length

  React.useEffect(() => {
    if (asyncHandler) {
      setLoading(true)
      asyncHandler({ order, orderBy, page, rowsPerPage }).finally(() =>
        setLoading(false),
      )
    }
  }, [order, orderBy, page, rowsPerPage])

  const handleAsync = () => {
    if (!async || !asyncHandler) {
      return
    }

    setLoading(true)
  }

  const handleRequestSort = (
    _: React.MouseEvent<unknown>,
    property: keyof Row,
  ) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
    handleAsync()
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleClick = (_: React.MouseEvent<unknown>, name: string) => {
    if (!selectable) return
    const selectedIndex = selected.indexOf(name)
    let newSelected: readonly string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
    handleAsync()
  }

  const handleChangeRowsPerPage = (_: any, newValue: number | null) => {
    setRowsPerPage(parseInt(newValue!.toString(), 10))
    setPage(0)
    handleAsync()
  }

  const getLabelDisplayedRowsTo = () => {
    if (rowCount === -1) {
      return (page + 1) * rowsPerPage
    }
    return rowsPerPage === -1
      ? rowCount
      : Math.min(rowCount, (page + 1) * rowsPerPage)
  }

  const isSelected = (name: string) => selected.indexOf(name) !== -1

  const columnCount = columns.length + (selectable ? 1 : 0)

  return (
    <Sheet
      variant="outlined"
      sx={{ width: "100%", borderRadius: "sm", position: "relative" }}
    >
      <EnhancedTableToolbar title={title} numSelected={selected.length} />
      <Table
        aria-labelledby="tableTitle"
        sx={{
          "--TableCell-headBackground": "transparent",
          "--TableCell-selectedBackground": (theme) =>
            theme.vars.palette.success.softBg,
        }}
      >
        <EnhancedTableHead
          columns={columns}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={rowCount}
          selectable={selectable}
        />
        <tbody>
          {(async
            ? rows
            : stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
              )
          ).map((row: Row, index) => {
            const isItemSelected = isSelected(row[rowKey])
            const labelId = `enhanced-table-checkbox-${index}`

            return (
              <tr
                onClick={(event) => handleClick(event, row[rowKey])}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row[rowKey]}
                style={
                  isItemSelected
                    ? ({
                        "--TableCell-dataBackground":
                          "var(--TableCell-selectedBackground)",
                        "--TableCell-headBackground":
                          "var(--TableCell-selectedBackground)",
                      } as React.CSSProperties)
                    : {}
                }
              >
                {selectable && (
                  <th scope="row">
                    <Checkbox
                      checked={isItemSelected}
                      slotProps={{
                        input: {
                          "aria-labelledby": labelId,
                        },
                      }}
                      sx={{ verticalAlign: "top" }}
                    />
                  </th>
                )}
                {columns.map(({ key, cellStyles, cellRenderer }) => (
                  <td key={key} style={cellStyles ?? {}}>
                    {cellRenderer
                      ? cellRenderer(get(row, key), row)
                      : get(row, key)}
                  </td>
                ))}
              </tr>
            )
          })}
          {loading && rowCount === 0 && (
            <tr
              style={
                {
                  height: `calc(${10} * 48px)`,
                  "--TableRow-hoverBackground": "transparent",
                } as React.CSSProperties
              }
            >
              <td colSpan={columnCount} aria-hidden />
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={columnCount}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  justifyContent: "flex-end",
                }}
              >
                <FormControl orientation="horizontal" size="sm">
                  <FormLabel>Rows per page:</FormLabel>
                  <Select
                    onChange={handleChangeRowsPerPage}
                    value={rowsPerPage}
                  >
                    <Option value={10}>10</Option>
                    <Option value={25}>25</Option>
                    <Option value={50}>50</Option>
                  </Select>
                </FormControl>
                <Typography textAlign="center" sx={{ minWidth: 80 }}>
                  {labelDisplayedRows({
                    from: rowCount === 0 ? 0 : page * rowsPerPage + 1,
                    to: getLabelDisplayedRowsTo(),
                    count: rowCount === -1 ? -1 : rowCount,
                  })}
                </Typography>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <IconButton
                    size="sm"
                    color="neutral"
                    variant="outlined"
                    disabled={page === 0}
                    onClick={() => handleChangePage(page - 1)}
                    sx={{ bgcolor: "background.surface" }}
                  >
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                  <IconButton
                    size="sm"
                    color="neutral"
                    variant="outlined"
                    disabled={
                      rowCount !== -1
                        ? page >= Math.ceil(rowCount / rowsPerPage) - 1
                        : false
                    }
                    onClick={() => handleChangePage(page + 1)}
                    sx={{ bgcolor: "background.surface" }}
                  >
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </Box>
              </Box>
            </td>
          </tr>
        </tfoot>
      </Table>
      {loading && (
        <Delayed waitBeforeShow={200}>
          <LinearProgress
            thickness={2}
            style={{ position: "absolute", bottom: 0, left: 3, right: 3 }}
          />
        </Delayed>
      )}
    </Sheet>
  )
}
