import { CustomFieldAnswer } from "src/models/infinicept/customFieldAnswer"
import axios from "./axiosConfig"
import { CancelToken } from "axios"

export const getTemplate = (cancelToken?: CancelToken) => {
  return axios.get("infinicept/template", { cancelToken })
}

export const getApplicationDetails = (
  groupId: string,
  merchantApplicationId: string,
  cancelToken?: CancelToken,
) => {
  return axios.get(
    `groups/${groupId}/merchantApplications/${merchantApplicationId}/infinicept`,
    { cancelToken },
  )
}

export const putApplication = (
  groupId: string,
  merchantApplicationId: string,
  customFieldAnswers: CustomFieldAnswer[],
  stepNumber: number,
  cancelToken?: CancelToken,
) => {
  return axios.put(
    `groups/${groupId}/merchantApplications/${merchantApplicationId}/infinicept`,
    customFieldAnswers,
    { cancelToken, params: { stepNumber } },
  )
}
