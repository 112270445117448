import { SyntheticEvent, useMemo } from "react"
import {
  Autocomplete,
  AutocompleteOption,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  ListItemContent,
  Stack,
  Typography,
  IconButton,
} from "@mui/joy"
import { getGroups } from "src/api/groups"
import {
  createMerchantApplication,
  updateMerchantApplication,
} from "src/api/merchantApplications"
import { useRef, useState } from "react"
import { debounce, truncate } from "lodash"
import { Group } from "src/models"
import { MerchantApplication } from "src/models/merchantApplication"
import { useNavigate } from "react-router-dom"
import VisuallyHiddenInput from "src/components/VisuallyHiddenInput"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import * as Yup from "yup"
import { useFormik } from "formik"
import ClearIcon from "@mui/icons-material/Clear"
import { useDispatch } from "react-redux"
import { incrementStep, selectApplication } from "./applicationEditorSlice"
import { useAppSelector } from "src/app/hooks"

type Status = "pristine" | "loading" | "idle"

type Params = { [key: string]: any }

interface FormData {
  group: Group | null
  merchantName: string
  boardJackHenry: boolean
  boardWorldpay: boolean
  file: File | null
}

function General() {
  const [options, setOptions] = useState<Group[]>([])
  const [status, setStatus] = useState<Status>("pristine")
  const application = useAppSelector(selectApplication)

  const dispatch = useDispatch()

  const hiddenFileInput = useRef<HTMLInputElement | null>(null)

  const navigate = useNavigate()

  const formik = useFormik<FormData>({
    enableReinitialize: true,
    initialValues: {
      group: application?.group ?? null,
      merchantName: application?.merchantName ?? "",
      boardJackHenry: application?.boardJackHenry ?? false,
      boardWorldpay: application?.boardWorldpay ?? false,
      file: null,
    },
    validationSchema: Yup.object({
      group: Yup.object().required("Group is required"),
      merchantName: Yup.string()
        .required("Merchant Name is required")
        .max(255, "Merchant Name must be 255 characers or less"),
    }),
    onSubmit: async (values) => {
      const groupUid = values.group!.uniqueId!

      const data = {
        merchantName: values.merchantName,
        boardJackHenry: values.boardJackHenry,
        boardWorldpay: values.boardWorldpay,
        step: 0,
      }

      const promise = !!application
        ? updateMerchantApplication(groupUid, application.uniqueId, data)
        : createMerchantApplication(groupUid, data)

      return promise
        .then((res) => res.data)
        .then((merchantApplication: MerchantApplication) => {
          dispatch(incrementStep())
          navigate(`/merchants/app/${groupUid}/${merchantApplication.uniqueId}`)
        })
    },
  })

  const debouncedFetchOptions = useRef(
    debounce(
      (params: Params) => {
        getGroups(params)
          .then((res) => res.data.data)
          .then((data) => {
            setOptions(data)
            setStatus("idle")
          })
      },
      250,
      { maxWait: 1000 },
    ),
  ).current

  const handleInputChange = (
    e?: SyntheticEvent<Element>,
    searchString?: string,
  ) => {
    // ignore anything other than change events (e.g. click events)
    // to prevent unnecessary data fetching
    if (!e || e.type !== "change") {
      return
    }

    setStatus("loading")

    const params: Params = {
      include: "partner",
      orderBy: "name",
      isActive: true,
      pageSize: 10,
    }

    if (searchString) {
      params["name.like"] = searchString
    }

    debouncedFetchOptions(params)
  }

  const getNoOptionsText = useMemo(() => {
    switch (status) {
      case "loading":
        return null
      case "pristine":
        return "Type to search for groups"
      default:
        return "No groups found"
    }
  }, [status])

  return (
    <div>
      <Stack
        component="form"
        id="form"
        sx={{ maxWidth: 500, mb: 3 }}
        spacing={3}
        onSubmit={formik.handleSubmit}
      >
        <Card variant="soft">
          <CardContent component={Stack} spacing={1}>
            <FormControl required>
              <FormLabel>
                <Typography level="title-md">Select a Group</Typography>
              </FormLabel>
              <Autocomplete
                {...formik.getFieldProps("group")}
                onChange={(_, value) => formik.setFieldValue("group", value)}
                onInputChange={handleInputChange}
                placeholder="Type to search..."
                noOptionsText={getNoOptionsText}
                options={options}
                autoFocus
                isOptionEqualToValue={(option, value) =>
                  option.uniqueId === value.uniqueId
                }
                getOptionLabel={(option: any) => option.name}
                renderOption={(props, option: any) => (
                  // override the key prop because there may be multiple groups
                  // under different partners with the same name
                  <AutocompleteOption {...props} key={option.uniqueId}>
                    <ListItemContent>
                      {option.name}
                      <Typography level="body-xs">
                        {option.partner.name}
                      </Typography>
                    </ListItemContent>
                  </AutocompleteOption>
                )}
                endDecorator={
                  status === "loading" ? (
                    <CircularProgress
                      size="sm"
                      sx={{ bgcolor: "background.surface" }}
                    />
                  ) : null
                }
              />
            </FormControl>
            <FormControl
              required
              error={
                formik.touched.merchantName && !!formik.errors.merchantName
              }
            >
              <FormLabel>
                <Typography level="title-md">Merchant Name</Typography>
              </FormLabel>
              <Input type="text" {...formik.getFieldProps("merchantName")} />
              {formik.touched.merchantName && !!formik.errors.merchantName && (
                <FormHelperText>{formik.errors.merchantName}</FormHelperText>
              )}
            </FormControl>
          </CardContent>
        </Card>
        <Card variant="soft">
          <Typography level="title-md">Choose Payment Processors</Typography>
          <CardContent component={Stack} spacing={1}>
            <Checkbox
              label="Jack Henry"
              name="boardJackHenry"
              onChange={formik.handleChange}
              checked={formik.values.boardJackHenry}
            />
            <Checkbox
              label="Worldpay"
              name="boardWorldpay"
              onChange={formik.handleChange}
              checked={formik.values.boardWorldpay}
            />
          </CardContent>
        </Card>
        {/* <Card variant="soft">
          <Typography level="title-md">Upload Data</Typography>
          <CardContent orientation="horizontal">
            <ButtonGroup variant="outlined" color="neutral">
              <Button
                component="label"
                role={undefined}
                tabIndex={-1}
                startDecorator={<CloudUploadIcon />}
              >
                {!!formik.values.file
                  ? truncate(formik.values.file.name, { length: 50 })
                  : "Choose file..."}
                <VisuallyHiddenInput
                  type="file"
                  name="file"
                  ref={hiddenFileInput}
                  onChange={(e) =>
                    formik.setFieldValue(
                      "file",
                      e.currentTarget.files?.[0] ?? null,
                    )
                  }
                />
              </Button>
              {!!formik.values.file && (
                <IconButton
                  onClick={() => {
                    hiddenFileInput.current!.value = ""
                    formik.setFieldValue("file", null)
                  }}
                >
                  <ClearIcon />
                </IconButton>
              )}
            </ButtonGroup>
          </CardContent>
        </Card> */}
      </Stack>
      <Button type="submit" form="form" loading={formik.isSubmitting}>
        Continue
      </Button>
    </div>
  )
}

export default General
