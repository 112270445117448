import { FormControl, FormLabel, Input, Typography } from "@mui/joy"
import { FormEvent, Fragment } from "react"
import { FieldComponentProps } from "./types"

function ComplexNameField({
  field,
  value,
  errors,
  onChange,
}: FieldComponentProps) {
  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    onChange(field, { [event.currentTarget.name]: event.currentTarget.value })
  }

  return (
    <Fragment>
      <Fragment key={field.id}>
        <Typography level="title-md">{field.prompt}</Typography>
        <FormControl required={field.isRequired} error={!!errors?.firstName}>
          <FormLabel>First Name</FormLabel>
          <Input
            placeholder={field.placeholderText}
            type="text"
            onChange={handleChange}
            name="firstName"
            value={value.firstName}
          />
        </FormControl>
        <FormControl error={!!errors?.middleName}>
          <FormLabel>Middle Name</FormLabel>
          <Input
            placeholder={field.placeholderText}
            type="text"
            onChange={handleChange}
            name="middleName"
            value={value.middleName}
          />
        </FormControl>
        <FormControl required={field.isRequired} error={!!errors?.lastName}>
          <FormLabel>Last Name</FormLabel>
          <Input
            placeholder={field.placeholderText}
            type="text"
            onChange={handleChange}
            name="lastName"
            value={value.lastName}
            required={field.isRequired}
          />
        </FormControl>
      </Fragment>
    </Fragment>
  )
}

export default ComplexNameField
