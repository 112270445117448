import { Switch, Typography } from "@mui/joy"
import React from "react"
import ColorSchemeToggle from "./ColorSchemeToggle"

type Props = {}

function Settings({}: Props) {
  return (
    <div>
      <Typography level="h2" sx={{ mb: 3 }}>
        Settings
      </Typography>
      <ColorSchemeToggle sx={{ ml: "auto" }} />
    </div>
  )
}

export default Settings
