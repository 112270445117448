import validator from "validator"
import { isPossiblePhoneNumber } from "libphonenumber-js"
import { isEmpty } from "lodash"
import { CustomField } from "src/models/infinicept/customField"
import { CustomFieldValue } from "src/models/infinicept/customFieldValue"

const required = (
  field: CustomField,
  value: CustomFieldValue,
): { [key: string]: string } | undefined => {
  if (field.isRequired) {
    const emptyKeys = Object.entries(value).filter(([k, v]) => !v.length)
    if (emptyKeys.length) {
      return emptyKeys.reduce(
        (acc, [k, v]) => ({ ...acc, [k]: "This field is required" }),
        {},
      )
    }
  }

  if (field.validation1?.validationTypeName === "RegexMatch") {
    const regExp = new RegExp(field.validation1.regexPattern!)
    const invalidKeys = Object.entries(value).filter(
      ([k, v]) => !v.match(regExp),
    )
    if (invalidKeys.length) {
      return invalidKeys.reduce(
        (acc, [k, v]) => ({ ...acc, [k]: "Please enter a valid value" }),
        {},
      )
    }
  }
}

const textField = (field: CustomField, value: CustomFieldValue) => {
  const requiredMessage = required(field, value)

  if (requiredMessage) return requiredMessage

  const val = value["#"]

  const maxLength = field.validation1?.maxLength!
  const minLength = field.validation1?.minLength!

  if (
    maxLength > 0 &&
    val?.length &&
    val.length > field.validation1!.maxLength!
  ) {
    return { "#": `This field cannot exceed ${maxLength} characters` }
  }

  if (minLength > 0 && val?.length && val.length < minLength) {
    return { "#": `This field cannot exceed ${maxLength} characters` }
  }
}

const numberField = (field: CustomField, value: CustomFieldValue) => {
  const requiredMessage = required(field, value)
  if (requiredMessage) return requiredMessage

  const val = parseInt(value["#"]!)

  if (
    field.maximumValue &&
    !isNaN(parseInt(field.maximumValue)) &&
    !isNaN(val) &&
    val > parseInt(field.maximumValue)
  ) {
    return { "#": `Maximum value for this field is ${field.maximumValue}` }
  }

  if (field.minimumValue && !isNaN(val) && val < parseInt(field.minimumValue)) {
    return { "#": `Minimum value for this field is ${field.minimumValue}` }
  }
}

const phoneField = (field: CustomField, value: CustomFieldValue) => {
  const requiredMessage = required(field, value)
  if (requiredMessage) return requiredMessage

  const val = value["#"]!

  if (!!val?.length && !isPossiblePhoneNumber(val, "US")) {
    return { "#": "Please enter a valid phone number" }
  }
}

const emailField = (field: CustomField, value: CustomFieldValue) => {
  const requiredMessage = required(field, value)
  if (requiredMessage) return requiredMessage

  const val = value["#"]!

  if (!!val?.length && !validator.isEmail(val)) {
    return { "#": "Please enter a valid email address" }
  }
}

const urlField = (field: CustomField, value: CustomFieldValue) => {
  const requiredMessage = required(field, value)
  if (requiredMessage) return requiredMessage

  const val = value["#"]!

  if (!!val?.length && !validator.isURL(val)) {
    return { "#": "Please enter a valid URL" }
  }
}

const addressField = (field: CustomField, value: CustomFieldValue) => {
  const { country, street2, ...rest } = value
  let requiredMessage = required(field, rest)

  const { zip } = value
  if (!!zip?.length && !validator.isPostalCode(zip!, "US")) {
    if (requiredMessage) {
      if (!requiredMessage.zip) {
        requiredMessage.zip = "Please enter a valid zip code"
      }
    } else {
      return { zip: "Please enter a valid zip code" }
    }
  }

  if (requiredMessage) return requiredMessage
}

const dateField = (field: CustomField, value: CustomFieldValue) => {
  const requiredMessage = required(field, value)
  if (requiredMessage) return { "#": "This field is required" }
}

const nameField = (field: CustomField, value: CustomFieldValue) => {
  const { middleName, ...rest } = value
  const requiredMessage = required(field, rest)
  if (requiredMessage) return requiredMessage
}

export {
  textField,
  numberField,
  phoneField,
  emailField,
  urlField,
  addressField,
  dateField,
  nameField,
  required,
}
