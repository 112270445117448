import { Box, CircularProgress } from "@mui/joy"

function Spinner() {
  return (
    <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
      <CircularProgress />
    </Box>
  )
}

export default Spinner
